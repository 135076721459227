.score__details .group-info,
.score__details .user-info {
    display: flex !important;
    justify-content: center;
    flex: 2;
}

.votes {
    display: flex;
    justify-content: center;
    flex: 1;
}

.vote-icons {
    display: flex;
}
