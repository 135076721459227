/* TODO DRY WITH UserInfo.css */

.group-info-container.is-votee {
  border: 3px dashed var(--ion-color-medium);
  border-radius: 12px;
}

.group-info-container.is-votee > div {
  XXbackground: var(--main-bg);
}

.group-info {
  width: 100%;
}

.group-info .progress-container > * {
  padding: .75rem;
}

.group-info-container .ion-accordion-toggle-icon {
  margin-right: 5px;
}
