.votes {
  display: flex;
  align-items: center;
  Xjustify-content: space-between;
  Xwidth: 100%;
  flex: 2 1;
  position: relative;
  Xfont-size: 4rem;
}

.sidebar .votes {
  flex-direction: column;
}

.sidebar .votes {
  text-align: center;
}

.vote-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.percentage-votes path {
  stroke: var(--ion-color-light-shade);
}

.percentage-votes path + path {
  stroke: var(--ion-color-light);
  stroke: #fff;
}

.vote-icons.abstained::before {
  content: "";
  width: calc(100% - 40px);
  position: absolute;
  top: 56%;
  border-top: 3px dashed #ff8383;
}

.abstain-from-voting,
.delete-votes {
  margin-left: 5px;
}

.abstain-from-voting:hover,
.delete-votes:hover {
  cursor: pointer;
}

.vote-icon {
  padding: 0 3px;

  /* Prevent scrolling when touch moving the element */
  touch-action: none;
}

.interactive .vote-icon {
  cursor: pointer;
}

.interactive .vote-icon:hover {
  transform: scale(1.2);
}


[data-num-votes="0"] .delete-votes {
  display: none;
  cursor: default;
}

.delete-votes:hover svg {
  animation: rub-out 1.2s cubic-bezier(.36, .07, .19, .97) both;
}

@keyframes rub-out {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(3px, 0, 0);
  }
}

.abstain-from-voting {
  display: none;
  margin: 0 3px 0 5px;
}

[data-num-votes="0"] .abstain-from-voting {
  display: inline;
}




/* React Rating : https://github.com/smastrom/react-rating & https://react-rating.onrender.com/#halffill_-_box */
.rr--group {
  display: flex;
  width: 100%;
}

.rr--box {
  display: flex;
  width: 100%;
  flex-grow: 1;
}

.rr--dir-x.rr--gap-sm .rr--svg {
  /*margin: 0 6.25%;*/
  /* Better without margin for touch dragging/sliding purposes */
  margin: 0;
}

.rr--space-sm .rr--svg {
  padding: 10%;
}

.rr--svg {
  display: flex;
  aspect-ratio: 1;
  width: 100%;
  flex-grow: 1;
  overflow: visible;
}


.rr--has-stroke .rr--svg {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.rr--has-stroke .rr--hf-svg-on {
  stroke: var(--rr--stroke-on-color, currentColor);
}

/* HS */
.rr--has-stroke .rr--hf-svg-off {
  stroke: var(--rr--stroke-off-color, currentColor);
}

.rr--hf-svg-on {
  fill: var(--rr--fill-on-color, none);
}

.rr--svg-stop-1 {
  /* stop-color: var(--rr--box-on-color, rgba(0, 0, 0, 0)); */
  stop-color: var(--rr--fill-on-color, rgba(0, 0, 0, 0));
  stop-color: var(red, rgba(0, 0, 0, 0));
}

[dir=rtl] .rr--svg-stop-1,
.rr--svg-stop-2 {
  /* stop-color: var(--rr--box-off-color, rgba(0, 0, 0, 0)); */
  stop-color: var(--rr--fill-off-color, rgba(0, 0, 0, 0));
}

/* Boxed star */

.rr--hf-box-on .rr--svg {
  background-color: var(--rr--box-on-color, none);
}

.rr--hf-box-on .rr--svg,
.rr--hf-box-off .rr--svg,
.rr--hf-box-int .rr--svg {
  fill: var(--rr--fill-off-color, none);
}

.rr--dir-x .rr--hf-box-int .rr--svg {
  background: linear-gradient(to right, var(--rr--box-on-color, none) var(--fill-percent), var(--rr--box-off-color, none) var(--fill-percent));
}

/*.vote-icons {*/
.rr--box {
  /* See Half-fill - Box example of colour graduations for each rating */
  /* --rr--box-on-color: #da1600;
  --rr--box-on-color: #db711a;
  --rr--box-on-color: #dcb000;
  --rr--box-on-color: #61bb00;
  --rr--box-on-color: #009664; */

  /* Max rating colour by default */
  --rr--box-on-color: #009664;

  --rr--box-off-color: #C7C7C7;
  --rr--fill-off-color: lime;/*white;*/
}



/*
TODO MAKE VAR NAMES UNIFORM (--rr--box-on-color / --rr--box-off-color -> --rr--fill-on-color / --rr--fill-off-color ???)
--rr--fill-on-color: #ffb23f;
--rr--fill-off-color: #FFF7ED;
--rr--stroke-on-color: #e17b21;
--rr--stroke-off-color: #bbb; /x* #eda76a *x/
*/

/* Colour change different for each icon */
[data-rating-index="0"] .rr--box {
  --rr--box-on-color: #da1600;
}

[data-rating-index="1"] .rr--box {
  --rr--box-on-color: #db711a;
}

[data-rating-index="2"] .rr--box {
  --rr--box-on-color: #dcb000;
}

[data-rating-index="3"] .rr--box {
  --rr--box-on-color: #61bb00;
}

/* Colour change synchronised for all icons */
/*
[data-num-votes-rounded-up="1"] .rr--box {
  --rr--box-on-color: #da1600;
}

[data-num-votes-rounded-up="2"] .rr--box {
  --rr--box-on-color: #db711a;
}

[data-num-votes-rounded-up="3"] .rr--box {
  --rr--box-on-color: #dcb000;
}

[data-num-votes-rounded-up="4"] .rr--box {
  --rr--box-on-color: #61bb00;
}
*/


/*.vote-icons {*/
.rr--box {
  --rr--fill-on-color: #ffb23f;
  /* TODO PORTION OF RATING ICON 'UNFILLED' (OR WHOLE ICON IN CENTRE FOR BOXED ONES) - CHANGE SO CAN USE SAME VARS FOR INNER AND OUTER (BOX) PARTS! */
  --rr--fill-off-color: #FFF7ED; /* Very pale yellow */
  --rr--fill-off-color: white;
  --rr--stroke-on-color: #e17b21;
  --rr--stroke-off-color: #bbb; /*#eda76a*/
}




.custom-classname {
  --rr--border-width: 3px;
  --rr--box-off-color: #dddddd;
  --rr--border-off-color: #a8a8a8;
}


.custom-classname .rr--svg {
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}

.custom-classname .rr--on .rr--svg {
  fill: MediumSeaGreen;
  background: MintCream;
  border-color: MediumSeaGreen;
}

.custom-classname .rr--off .rr--svg {
  fill: Tomato;
  background: AntiqueWhite;
  border-color: Tomato;
}

@media (min-width: 610px) {
  .custom-classname {
    max-width: 300px;
    gap: 10px;
  }

  .custom-classname .rr--svg {
    padding: 10px;
    border-width: 2px;
    border-radius: 10px;
  }
}


.rr--fx-colors {
  --rr--easing: .15s cubic-bezier(.61, 1, .88, 1);
}

/* .rr--pointer .rr--box {
  cursor: pointer;
} */

/* @media (hover: hover) { */
  .scale--normal,
  .scale--medium,
  .scale--large,
  .rr--fx-zoom .rr--box {
    transition: transform var(--rr--easing); /*, top var(--rr--easing), padding var(--rr--easing), margin var(--rr--easing);*/
    transform: scale(1);
    position: relative;
  }

  /* Non touch / mouse */
  .vote-icon.scale--large {
    transform: scale(1.3);
  }

  .vote-icon.scale--large.touching {
    /*transform: scale(1.2) translateY(-10px);*/

    /* Touch */
    transform: scale(1.7);
    /*
     Do this way rather than with translate for better touch ergonomics,
     so user can slide on element where it was originally pressed even though
     the displacement may cause that position to become outside of the element.
    */
    /* TODO Why don't these animate? */
    top: -26px;
    padding-bottom: 26px;
    margin-bottom: -26px;
    /*border: 1px solid black;*/

    z-index: 1000;
  }
/* } */

.rr--fx-colors .rr--svg {
  transition-duration: .15s;
  transition-timing-function: var(--rr--easing);
  transition-property: background-color, border-color, fill, stroke;
}
