.header {
  background: var(--ion-color-light-shade);
  color: var(--ion-color-light-contrast);
}

.header > .toolbar {
  --background: transparent;
}

.header .content {
  display: flex;
  XXjustify-content: space-between;
  align-items: center;
  /* position: fixed;
  top: 0;
  left: 0; */
  width: 100%;
  height: 150px;
  padding: 0.75rem 1.5rem;
  /*background: var(--primary-col);
  color: var(--highlight-col);*/
  /* background: var(--ion-color-light-shade);
  color: var(--ion-color-light-contrast); */
  z-index: 10;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.header .content-title {
  text-shadow: -2px 2px #333, 2px 2px #333, -2px -2px #333, 2px 2px #333, 2px -2px #333, -7px 7px #333, -5px 5px #333, -4px 4px #333;
  color: #fff;
  /* font-size: 3rem; */
  font-size: 3.5rem;
  font-style: italic;
  text-align: center;
  width: 62%;
}

.header .content-left {
  flex: 1;
}

.header .content-centre {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header .content-right {
  flex: 1;
  justify-self: center;
}

.header h1 {
  font-size: 3rem;
}

/* .xmas-theme
.nav, .header .content {
  background: #e20e1b;
  color: hsl(356deg 32% 97%);
} */

.nav {
  background: var(--primary-col);
  color: #000;
  height: 80px;
}

.classvote-logo {
  display: flex;
  align-items: flex-start;
}

.classvote-logo img {
  position: absolute;

  /* top: 10px;
  left: 15px;
  width: 180px;
  height: 50px; */

  /* top: 55px;
  left: 15px;
  width: 280px;
  height: 90px; */

  top: 20px;
  left: 15px;
  width: 200px;
  height: 60px;
}
/*
.classvote-logo svg g {
  fill: var(--highlight-col);
}
*/

/* .header .content .classvote-logo {
  position: relative;
  top: -50px;
  z-index: 10;
  transform-origin: 20% 160%;
  transform: translate(50px, 30px) rotateZ(-30deg) scale(1.7);
} */

@media (max-width: 1023px) {
  .header .content-title {
    text-shadow: -2px 2px #333, 2px 2px #333, -2px -2px #333, 2px 2px #333, 2px -2px #333, -5px 5px #333, -4px 4px #333;
    font-size: 2.1rem;
  }
}
