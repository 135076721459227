.votee-pointer {
  height: 70px;
  animation: move-up-down 2.5s linear infinite;
}

.votee-pointer.hidden {
  visibility: hidden;
}

@keyframes move-up-down {
  0%, 100% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(30px);
  }
}
