.vote-area {
  /*flex: 6;*/
  width: 100%;
  /*background: var(--main-bg);
  color: var(--primary-col);*/
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
}

.vote-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 30px;
}

.vote-desc p {
  font-size: 1.6rem;
  text-align: center;
  margin: 10px 0;
}

.vote-desc img {
  min-width: 150px;
  max-width: 350px;
  width: 40%;
}

.votee-info {
  display: flex;
  flex-direction: column;
  background: var(--dark-bg);
  color: var(--main-bg);
  background: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
  border-radius: 6px;
}

.votee-info .progress-container > * {
  padding: 0 15px;
}

@keyframes question-anim {
	from {
		transform: translateY(25px);
    opacity: 0;
	}
	to { 
		transform: translateY(0);
    opacity: 1;
  }
}

.questions-title {
    text-shadow: -1px 1px #333, 1px 1px #333, -1px -1px #333, 1px 1px #333, 1px -1px #333, -4px 4px #333, -3px 3px #333;
    color: #fff;
    font-size: 2.3rem;
    font-style: italic;
    text-align: center;
}

.question-container {
  /* background: #ffffffc0; */
  background: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
  margin: 10px 0 25px;
  border-radius: 16px;

  /* So vote icons can been seen when goes outside card (when animating etc) */
  overflow: visible;

  animation-name: question-anim;
	animation-duration: .6s;
	animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-fill-mode: backwards;
  animation-play-state: paused;
  animation-delay: calc(var(--delay-factor) * 200ms);
}

.question-container .progress-container {
  padding: 20px 0;

  /* So vote icons can been seen when goes outside card (when animating etc) */
  overflow: visible;
}

.sidebar .question-container .progress-container,
.sidebar .question-container {
  padding: 0;
  margin: 10px 0;
}

.question-container.show,
.question-container:first-of-type,
.question-container.show + .question-container:not(.show) {
  animation-play-state: running;
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.question-title {
    flex: 3 0;
    font-size: 1.6rem;
    margin: 20px 0;
}

.sidebar .question {
  padding: 0;
  margin: -8px 0;
}

.sidebar .question-title {
  font-size: 1.1rem;
  margin: 10px 0;
}

@media (max-width: 1023px) {
  .question {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }

  .questions-title {
    text-shadow: -1px 1px #333, 1px 1px #333, -1px -1px #333, 1px 1px #333, 1px -1px #333, -3px 3px #333;
    font-size: 1.7rem;
  }

  .vote-desc p {
    font-size: 1.4rem;
  }
}

/* .entity-info h3 {
  color: red;
} */
