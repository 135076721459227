/* TODO DRY WITH GroupInfo.css */

.user-info-container.is-votee {
  border: 3px dashed var(--ion-color-medium);
  border-radius: 12px;
}

.user-info-container.is-votee .start {
  Xbackground: var(--ion-color-light-shade);
  XXbackground: green;
}

.user-info {
  width: 100%;
}

.user-info .progress-container > * {
  padding: .75rem;
}

.user-info-container .ion-accordion-toggle-icon {
  margin-right: 5px;
}

/*
[slot='header'] {
  background: orange;
}
*/

:root {
  --ion-color-rose: #fecdd3;
  --ion-color-rose-rgb: 254, 205, 211;
  --ion-color-rose-contrast: #000000;
  --ion-color-rose-contrast-rgb: 0, 0, 0;
  --ion-color-rose-shade: #e0b4ba;
  --ion-color-rose-tint: #fed2d7;
}

.ion-color-rose {
  --ion-color-base: var(--ion-color-rose);
  --ion-color-base-rgb: var(--ion-color-rose-rgb);
  --ion-color-contrast: var(--ion-color-rose-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rose-contrast-rgb);
  --ion-color-shade: var(--ion-color-rose-shade);
  --ion-color-tint: var(--ion-color-rose-tint);
}

/*
div[slot='content'] {
  background: rgba(var(--ion-color-rose-rgb), 0.25);
}
*/

.entity-info.user-info.info-context-lobby > * {
  font-size: 2em;
  text-align: center;
}
