/** App.css IS A TEMP FILE!!!!!!!!!!!!!! (TO BE MOVED INTO EACH COMPONENT CSS FILE) */

:root {
  --main-bg: #f1e9da;
  --sidebar-bg: #541388;
  --sidebar-bg-lighter: #9853cf;
  --very-dark-bg: #2e294e;
  --dark-bg: #3f69c7;
  --highlight-col: hsl(50, 100%, 50%);
  --primary-col: #d90368;
  --primary-col-slighty-darker: #a3014d;
  /*--primary-col: #c52e75;*/
}

/* :root .xmas-theme {
  --primary-col: #199e3a;
  --dark-bg: hsl(356deg 85% 54%);
}

.xmas-theme
.layout {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
}

.xmas-theme
.bg-blue {
  background: #8c420d;
}

.xmas-theme
.bg-blue-dark {
  background: #834112;
} */

.entities-info > *,
.entities-info {
  /* --ion-item-background: limegreen;
  --ion-background-color: limegreen;
  --background: limegreen; */
}

.user-removed {
  text-decoration: line-through;
  opacity: .5;
}

.user-info .progress-container {
  --progress-indicator-stripe-1-colour: var(--ion-color-light-shade);
  --progress-indicator-stripe-2-colour: var(--ion-color-light);
}

/* .user-info .progress-container > * {
  padding: .75rem;
} */

/* .user-info::before {
  content: "";
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--progress);
  height: 100%;
  background: var(--sidebar-bg-lighter);
  transition: all .5s cubic-bezier(0.24, -0.31, 0.84, 1.35) 0s;
} */

.user-info .finished-voting-icon {
  opacity: 0;
  transition: opacity .5s;
  position: absolute;
  top: 14px;
  right: 8px;
}

.user-info[data-progress="100"] .finished-voting-icon {
  opacity: 1;
}

/* .xmas-theme
.sidebar {
  background: hsl(356deg 85% 54%);
} */

/* body {
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Slackey', cursive;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
} */

/* .layout {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100vh;
  background-color: rgb(245, 245, 245);
} */

.row-container {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 180px; /* TODO Allow for header or nav - will need to be dynamic (PERHAPS PUT NAV INSIDE HEADER SO ONLY NEED TO MANAGE HEADER RESIZING) */
}

.row-container section {
  max-width: 600px;
  margin-right: 20px;
}

.row-container section:last-child {
  margin: 0;
}

@media (max-width: 1023px) {
  .row-container {
    flex-direction: column;
    width: 100%;
  }

  .row-container section {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }
}

/* section h2 {
  padding: 2rem;
  color: #000;
  background: var(--highlight-col);

  // Xmas
  color: #d20101;
  background: #199e3a; //var(--highlight-col);
  //background: radial-gradient(ellipse at bottom, #34A65F 0%, #0F8A5F 100%);
  text-shadow: 0px -5px 2px rgb(255 255 255);
} */

/* The rest of the content */
/*
.content {
  background: var(--main-bg);
  margin-top: 80px;
  height: calc(100vh - 80px);
}
*/

.sidebar {
  /* flex-none min-w-300 bg-blue overflow-y-auto */
  /*flex: 3;*/
  width: 100%;
  /* background: var(--highlight-col); */
  background: var(--ion-color-light-shade);
  color: var(--ion-color-light-contrast);
}

.sidebar h2 {
  color: #000;
  font-size: 1.6rem;
  text-align: center;
}

.sidebar h3 {
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
}

/* Scrollable chat area */
.chat-area {
  /*flex: 3;*/
  width: 100%;
  background: var(--main-bg);
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
}

.groups {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.groups button {
  display: flex;
  align-items: center;
  margin: 10px 8px;
  padding: 10px 24px;
  background: var(--primary-col);
  font-family: 'Slackey', cursive;
  font-size: 1.2rem;
  transition: color .2s, transform .5s;
}

/* .xmas-theme
.groups button {
  background: #e20e1b;
  color: hsl(356deg 32% 97%);
} */

.groups button.selected {
  padding: 10px 9px;
}

.groups button:hover {
  /*transform: scale(1.1) !important;*/
  color: #fff;
}

.groups .selected {
  color: #fff;
  opacity: 1;
  animation-name: tada;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.groups .leave-group {
  display: none;
  margin-left: 8px;
  padding: 0px 5px;
  border: 2px solid #000;
  border-radius: 20px;
  background: var(--primary-col);
  color: #000;
  font-size: .8rem;
}

.groups .selected .leave-group {
  display: inline;
}

.groups .selected {
}

.group-info .group-users-info {
  Xopacity: 0;
  Xtransition: opacity .5s;
  position: absolute;
  top: 14px;
  right: 8px;
}

.bounce-in {
  opacity: 0;
  animation-name: bounceIn;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

.tada-forever {
  animation-name: tada;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
} 

.input-field {
}

.input-field input {
  padding: 10px 15px;
  background: var(--primary-col);
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  border: 4px solid transparent;
}

.input-field input::placeholder {
  color: var(--primary-col-slighty-darker);
}

/* .xmas-theme
.input-field input::placeholder {
  color: #1f8037;
} */

.focus\:ring:focus {
  border-color: var(--sidebar-bg-lighter);
  box-shadow: none;
}






.score__list {
  position: relative;
  width: 65vw;
  max-width: 100%;
  height: 100%;
  margin: 30px auto;
  /* Apply perpective transform to each 'test_card' child element */
  /* This could alternatively be applied to the childs' animated transform attribute */
  perspective: 500px;
}

.score__card {
  position: absolute;
  will-change: transform, opacity;
  width: 100%;
}

.score__cell {
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  /*overflow: hidden;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;*/
  font-family: 'Slackey', cursive;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 18px 0 15px;
}

.score__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0px;
  left: 0px;
  width: 90%;
  height: 50px;
  margin: 0 5%;
  box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.2);
  background: var(--dark-bg);
  color: var(--main-bg);
}

.score__details .name {
  flex: 2;
}

.score__details .votes {
  flex: 1;
}




/* VOTOMETER */
.bar {
  xmargin-inline: auto; 
  display: flex; 
  xflex-wrap: wrap; 
  xgap: 2rem;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border: 2px cyan dashed;
}

.segment {
Xflex: 1 0 auto;
  border: 2px purple dashed;
    --width: 4rem;
    --skew: .5rem;
    --segment-color: #fff;
    --anim-duration: 3s;
    --fill-start: var(--skew);
    --fill-end: calc(var(--percent) * 1% + var(--skew));
--fill-end: calc(var(--percent) * 1px + var(--skew));
    --not-last-segment: 1;
    width: var(--width);
    Xaspect-ratio: 1/4.5;
    Xmargin-top: var(--skew);
    Xborder-top-left-radius: 50% var(--skew);
    Xborder-top-right-radius: 50% var(--skew);
    Xborder-bottom-left-radius: 50% var(--skew);
    Xborder-bottom-right-radius: 50% var(--skew);
   xdisplay: grid;
   xgrid-template-rows: auto max-content;
  xheight: 100px;
  xbackground: var(--fill-color);
    position: relative;

animation: fill-before var(--anim-duration) linear forwards;
Xdisplay: flex;
Xflex-direction: column;
}

.segment:last-of-type {
  --not-last-segment: 0;
}

.segment .fill {
  xdisplay: flex;
  xjustify-content: center;
  xalign-items: center;
  
    Xmargin-top: calc(var(--skew) * -1);
    position: relative;
    z-index: 1;
    --glas-gradient:
        linear-gradient(
            to right,
            rgb(0 0 0 / .25),
            transparent 10% 15%,
            rgb(255 255 255 / .5) 20%,
            transparent 50% 90%,
            rgb(0 0 0 / .125) 
        );
    Xbackground-image: var(--glas-gradient);

height: 100%;
Xanimation: fill-before var(--anim-duration) linear forwards;
 }

.segment .fill::before {
  display: inline-block;
  Xposition: absolute;
    content: "";
    width: 100%;
  
    --XXfill-start: var(--skew);
    --XXfill-end: calc(var(--percent) * 1% + var(--skew));
    xheight: var(--fill-start);
    background-color: var(--fill-color);
    xbottom: calc(var(--skew) * -1);
    xborder-bottom-left-radius: 50% var(--skew);
    xborder-bottom-right-radius: 50% var(--skew);
    border-top-left-radius: 50% var(--skew);
    border-top-right-radius: 50% var(--skew);
    background-image:
        var(--glas-gradient),
        radial-gradient(
            ellipse 100% calc(var(--skew) * 2) at center calc(100% - var(--skew)), 
            rgb(255 255 255 /.125) calc(50% - 1px), 
            transparent 50%),
        linear-gradient(
            to right, 
            rgb(0 0 0 / .25), 
            transparent 50% 70%, 
            rgb(0 0 0 / .25));
  xbackground: var(--fill-color);
  Xanimation: fill-before var(--anim-duration) linear forwards;

height: 100%;
XXheight: calc(100% + var(--skew) * var(--not-last-segment));
animation: fill-before2 var(--anim-duration) linear forwards;
}

.XXsegment .fill::after {
    content: "";
    position: absolute;
    width: 100%;
    height: calc(var(--skew) * 2);
    background-color: var(--fill-color);
    border-radius: 50%;
    --fill-start: calc(var(--skew) * -1);
    --fill-end: calc(var(--percent) * 1% - var(--skew));
    bottom: var(--fill-start);
    Xbackground-image: 
        var(--glas-gradient),
        linear-gradient(to left, rgb(0 0 0 / .25), transparent 50%);
  animation: fill-after var(--anim-duration) linear forwards
}

@keyframes fill-before {to {height: var(--fill-end)}}
@keyframes fill-before2 {to {height: calc(100% + var(--skew) * var(--not-last-segment))}}
@keyframes fill-after {to {bottom: var(--fill-end)}}

/* for demo */
/*
*, *::before, *::after { box-sizing: border-box; padding: 0; margin: 0 }

body { 
  min-height: 100vh;  
  background-color: #C2C9CF; 
  font-family: 'Poppins', sans-serif; 
  padding: 1rem 
}
*/

.bars {
  xgap: calc(2vw + 0.2rem); /* (320,8)(1920,32) */;
}

.segment {
  --width: calc(8vw + 0.6rem); /* (320,24)(1920,96) */;
  --skew: calc(1vw + 0.1rem); /* (320,4)(1920,16) */;
}



/* VOTOMETER */
.bar .base {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  background-color: var(--tube-color);
background-color: transparent;
  background-image: linear-gradient(to right, rgb(0 0 0 / .25), transparent 50%);
  position: relative;
  display: grid;
  justify-items: center;
  text-align: center;
  padding-top: calc(var(--width) * 0.1 + var(--skew));
  padding-bottom: calc(var(--width) * .1);
  overflow: hidden;
}


.bar .base > .text {
  color: var(--fill-color);
  font-family: sans-serif;
  line-height: 1;
  font-size: calc(var(--width) * .4);
  position: relative;
}

.bar .base > .text > * {
  position: absolute;
  font-size: .5em;
}

.bar .base > .text > sub {
position: static;
font-size: .5em;
vertical-align: baseline;
}

.bar .base > .icon {
  width: 100%;
  color: var(--fill-color);
  background-color: rgb(0 0 0 / .1);
background-color: rgb(0 0 0 / .7);
  padding-top: calc(var(--skew) + var(--width) * .1);
  padding-bottom: calc(var(--width) * .1);
  display: grid;
  place-content: center;
  margin-top: calc(var(--skew) * -.5);
  border-bottom-left-radius: 50% var(--skew);
  border-bottom-right-radius: 50% var(--skew);
  --mask-image: radial-gradient( ellipse 100% calc(var(--skew) * 2) at center top, transparent calc(50% - 1px), black calc(50% + 1px));
  -webkit-mask-image: var(--mask-image);
  mask-image: var(--mask-image);
  font-size: calc(var(--width) * .25);
}














@keyframes progress-bar-bg-anim {
	from {
		transform: translateX(0);
	}
	to { 
		transform: translateX(46px);
  }
}

.progress-container {
  position: relative;
  overflow: hidden;
  border-radius: inherit;
  --progress-indicator-height: 100vh; /* Ensures covers all contained area */
  --progress-indicator-position-y: inherit;
  /* --progress-indicator-stripe-1-colour: rgba(0, 0, 0, .1);
  --progress-indicator-stripe-2-colour: rgba(255, 255, 255, .15); */
  --progress-indicator-stripe-1-colour: var(--ion-color-light-shade);
  --progress-indicator-stripe-2-colour: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
}

.progress-container--line {
  --progress-indicator-height: 2px;
  --progress-indicator-position-y: bottom;
}

.progress-container:not(.progress-translucent) {
  /* Background colour underlying translucent stripes - remove this and the stripes will blend with the existing background */
  /* Gradients cannot be transition animated so the background size is used for this */
  /* Note the size will not affect solid background, presumably as they infinitely repeat? */
  /* background: linear-gradient(90deg, var(--ion-color-medium-tint) 0%, var(--ion-color-medium-tint) 100%); */
  background: linear-gradient(90deg, var(--ion-color-light-shade) 0%, var(--ion-color-light-shade) 100%);
  background-repeat: no-repeat;
  background-size: var(--progress) var(--progress-indicator-height);
  background-position-y: var(--progress-indicator-position-y);
  transition: background .5s cubic-bezier(0.24, -0.31, 0.84, 1.35) 0s;
}

.progress-container > * {
  /* Needed so all siblings of .progress-bar will be superimposed */
  position: relative;
  z-index: 100;
}

.progress-container > .progress-bar {
  position: absolute;
  z-index: 0;
  top: 0;
  width: var(--progress);
  height: var(--progress-indicator-height);
  padding: 0;
  overflow: hidden;

  transition: width .5s cubic-bezier(0.24, -0.31, 0.84, 1.35) 0s;
}

.progress-container > .progress-bar > .progress-bar-bg {
  position: absolute;
  left: -46px;
  right: 0;
  top: 0;
  bottom: 0;

  /*
  background: -webkit-repeating-linear-gradient(
    145deg,
    #f8e9db 1px,
    #fff5ed 2px,
    #fff5ed 11px,
    #f8e9db 12px,
    #f8e9db 20px
  );
  background: repeating-linear-gradient(
    -55deg,
    #f8e9db 1px,
    #fff5ed 2px,
    #fff5ed 11px,
    #f8e9db 12px,
    #f8e9db 20px
	);
  */

  background: -webkit-repeating-linear-gradient(
    145deg,
    var(--progress-indicator-stripe-1-colour) 1px,
    var(--progress-indicator-stripe-2-colour) 2px,
    var(--progress-indicator-stripe-2-colour) 11px,
    var(--progress-indicator-stripe-1-colour) 12px,
    var(--progress-indicator-stripe-1-colour) 20px
  );

  background: repeating-linear-gradient(
    -55deg,
    var(--progress-indicator-stripe-1-colour) 1px,
    var(--progress-indicator-stripe-2-colour) 2px,
    var(--progress-indicator-stripe-2-colour) 11px,
    var(--progress-indicator-stripe-1-colour) 12px,
    var(--progress-indicator-stripe-1-colour) 20px
  );

  background-position-y: var(--progress-indicator-position-y);

  -webkit-animation-name: progress-bar-bg-anim;
	-webkit-animation-duration: .6s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;

  animation-name: progress-bar-bg-anim;
	animation-duration: .6s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}
